import React from 'react'
import { useCookies } from 'react-cookie'
import { navigate } from 'gatsby'
import requestData from '~/api/form'
import * as st from '~/assets/styl/Newsletter.module.styl'
import cn from 'classnames'

type Props = {
  justDesk?: boolean
}

const Newsletter = ({ justDesk }: Props) => {
  const [email, setEmail] = React.useState('')
  const [termos, setTermos] = React.useState(false)
  const [policy, setPolicy] = React.useState(false)
  const [communications, setCommunications] = React.useState(false)
  const [cookies] = useCookies()

  const formatDate = () => {
    const date = new Date()
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  const handleTerms = (e) => {
    setTermos(e.target.checked)
    setPolicy(e.target.checked)
  }

  const requestBody = {
    Email: email,
    Fluxo_midia: 'Formulário Newsletter',
    Data_criacao: formatDate(),
    Aceite_de_comunicacao: termos,
    Politica_de_privacidade: policy,
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    requestData('post', 'salesforce', requestBody).then(
      ({ code, messsage }) => {
        if (code === 0) navigate('/sucesso/')
        else alert(messsage)
      }
    )
  }

  return (
    <div className={cn(st.core, 'container', justDesk && st.noDisplay)}>
      <section>
        <h2>Assine nossa newsletter</h2>
        <p>Receba dicas exclusivas sobre seguros</p>
        <form action="" onSubmit={handleSubmit}>
          <input
            type="email"
            id="newsletter-email"
            name="email"
            placeholder="Insira seu melhor e-mail"
            required
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
          <button>Cadastrar</button>
          <input
            type="checkbox"
            value="termos"
            name="termos"
            id="newsletter-termos"
            required
            checked={termos}
            onChange={handleTerms}
          />
          <label htmlFor="newsletter-termos">
            Declaro que li e aceito os{' '}
            <a href="/termos-de-uso/">termos de uso</a> e{' '}
            <a href="/politica-de-privacidade/">política de privacidade.</a>
          </label>
          <input
            type="checkbox"
            value="communications"
            name="communications"
            id="newsletter-communications"
            required
            checked={communications}
            onChange={({ target }) => setCommunications(target.checked)}
          />
          <label htmlFor="newsletter-communications">
            Autorizo o Grupo Dimas a armazenar meus dados pessoais para enviar
            campanhas de marketing e informações sobre a empresa nos canais:
            Telefone, Email e SMS.
          </label>
        </form>
      </section>
    </div>
  )
}

export default Newsletter
