import React from 'react'
import { useCookies } from 'react-cookie'
import { navigate } from 'gatsby'
import requestData from '~/api/form'
import InputMask from 'react-input-mask'
import * as st from '~/assets/styl/Form.module.styl'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

interface Props {
  textBtn: string
  id?: string
}

const Form = ({ textBtn, id }: Props) => {
  const [nome, setNome] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [whatsapp, setWhatsapp] = React.useState('')
  const [mensagem, setMessage] = React.useState('')
  const [termos, setTermos] = React.useState(false)
  const [policy, setPolicy] = React.useState(false)
  const [communications, setCommunications] = React.useState(false)
  const [cookies] = useCookies()

  const handleTerms = (e) => {
    setTermos(e.target.checked)
    setPolicy(e.target.checked)
  }

  const formatDate = () => {
    const date = new Date()
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const c_utmz = cookies?.['__trf.src'] || cookies?.__utmzz
    const requestBody = {
      Email: email,
      nome: nome,
      Telefone: whatsapp.replace(/\D/g, ''),
      Mensagem: mensagem,
      Fluxo_midia: 'Formulário de Contato',
      Data_criacao: formatDate(),
      Aceite_de_comunicacao: termos,
      Politica_de_privacidade: policy,
    }
    requestData('post', 'salesforce', requestBody)
      .then(({ code, message }) => {
        if (code === 0) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'GA - Eventos',
            eventCategory: 'Envio de formulário',
            eventLabel: 'Formulário de contato',
            eventAction: 'Conversão',
          })
          navigate('/sucesso/')
        } else {
          alert(message || 'Erro ao enviar formulário')
        }
      })
      .catch(() => {
        alert('Erro de conexão. Por favor tente novamente.')
      })
  }

  return (
    <form className={st.core} onSubmit={handleSubmit}>
      <label htmlFor="nome">Nome</label>
      <input
        type="text"
        id="nome"
        name="nome"
        placeholder="Insira seu nome completo"
        required
        value={nome}
        onChange={(e) => setNome(e.target.value)}
      />
      <label htmlFor="email">E-mail</label>
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Insira seu melhor e-mail"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <label htmlFor="telefone">WhatsApp</label>
      <InputMask
        type="text"
        id="telefone"
        name="telefone"
        placeholder="Insira seu DDD + número de WhatsApp"
        mask="(99) 99999-9999"
        required
        value={whatsapp}
        onChange={(e) => setWhatsapp(e.target.value)}
      />
      <label htmlFor="mensagem">Mensagem</label>
      <textarea
        id="mensagem"
        name="mensagem"
        placeholder="Insira sua mensagem ou questionamento"
        value={mensagem}
        onChange={(e) => setMessage(e.target.value)}
        required
      ></textarea>
      <input
        type="checkbox"
        value="termos"
        name="termos"
        id="termos"
        required
        checked={termos}
        onChange={handleTerms}
      />
      <label htmlFor="termos">
        Declaro que li e aceito os <a href="/termos-de-uso/">termos de uso</a> e{' '}
        <a href="/politica-de-privacidade/">política de privacidade.</a>
      </label>
      <input
        type="checkbox"
        value="communications"
        name="communications"
        id="communications"
        required
        checked={communications}
        onChange={({ target }) => setCommunications(target.checked)}
      />
      <label htmlFor="communications">
        Autorizo o Grupo Dimas a armazenar meus dados pessoais para enviar
        campanhas de marketing e informações sobre a empresa nos canais:
        Telefone, Email e SMS.
      </label>
      <div>
        <button>{textBtn}</button>
      </div>
    </form>
  )
}

export default Form
